import { PwgsApi } from './PwgsApi.js'

export default class EspecialidadesService
{
    /**
     * @private {PwgsApi}
     */
    #pwgsapi = false;

    constructor() {
        this.#pwgsapi = new PwgsApi();
    }

    async getEspecialidades() {
        return await this.#pwgsapi.get('especialidades');
    }

    async getEspecialidad(id) {
        return await this.#pwgsapi.get('especialidades/' + id);
    }

    async postEspecialidades(datos) {
        return await this.#pwgsapi.post('especialidades', datos);
    }

    async putEspecialidades(datos) {
        return await this.#pwgsapi.put('especialidades', datos);
    }

    async deleteEspecialidad(id) {
        return await this.#pwgsapi.delete('especialidades/' + id);
    }
}