import { PwgsApi } from './PwgsApi.js'

export default class SeriesService
{
    /**
     * @private {PwgsApi}
     */
    #pwgsapi = false;

    constructor() {
        this.#pwgsapi = new PwgsApi();
    }

    async getSeries() {
        return await this.#pwgsapi.get('lineas-facturacion');
    }
}