<template>
  <Toast /> 
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  /**
   * Acciones de inicialización única en toda la app.
   * Aquí queremos cargar datos disponibles para toda la app
   */
  created() {
    this.$store.dispatch('datosApp/obtenerDatosGenerales');
  }
}
</script>
