<template>
    <!-- Renderiza un enlace de la aplicación,
    distinguiendo entre enlaces internos por VueRouter, y
    enlaces externos o con almohadilla -->
    <a v-if="isExternal" :href="to"><slot/></a>
    <router-link v-else v-bind="$props"><slot /></router-link>
</template>

<script>
import { RouterLink } from 'vue-router'

export default{
  props:{ ...RouterLink.props },
  components: {
      RouterLink
  },
  computed:{
    isExternal(){
      return typeof this.to === 'string' && 
      (this.to.startsWith('http') || this.to.startsWith('#'))
    }
  }
}
</script>