import EspecialidadesService from "../services/EspecialidadesService";
/**
 * Almacén de datos generales de la aplicación
 */
 export default {
    namespaced: true,
    state() {
        return {
            especialidades: [], 
        }
    },
    mutations: {
        asignarEspecialidades(state, datos) {
            state.especialidades = datos;
        },
    },
    getters: {
        especialidades(state) {
            return state.especialidades;
        },
    },
    actions: {
        async obtenerEspecialidades(context) {
            if (context.state.especialidades.length === 0) {
                const serv = new EspecialidadesService();
                const datos = await serv.getEspecialidades();
                context.commit('asignarEspecialidades', datos);
            }
        },
    }
}