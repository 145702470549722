<template>
    <button type="button" class="btn btn-block btn-default btn-xs">
        <div class="loading" v-if="guardando">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">{{ $t('general.cargando') }}</span>
        </div>
        {{ $t('general.guardar') }}
    </button>
</template>
<script>
export default {
    props: {
        guardando: Boolean
    }
}
</script>
<style scoped>
.loading {
    display: inline;
}
</style>