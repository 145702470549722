import { PwgsApi } from './PwgsApi.js'

export default class OtrosService
{
    #pwgsapi = false;

    constructor() {
        this.#pwgsapi = new PwgsApi();
    }

    async getSistemas(estado) {
        estado = estado || 'activos';
        return await this.#pwgsapi.get('sistemas/' + estado);
    }

    async getProvincias() {
        return await this.#pwgsapi.get('provincias');
    }

    async getCorreos() {
        return await this.#pwgsapi.get('correos');
    }

    async getEmpresas() {
        return await this.#pwgsapi.get('empresas');
    }

    async getImpuestos() {
        return await this.#pwgsapi.get('impuestos');
    }
}