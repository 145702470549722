import OtrosService from "../services/OtrosService";
import SeriesService from "../services/SeriesService";

/**
 * Almacén de datos generales de la aplicación
 */
export default {
    namespaced: true,
    state() {
        return {
            sistemas: [], 
            provincias: [],
            correos: [],
            empresas: [],
            series: [],
            impuestos: [],
        }
    },
    mutations: {
        asignarSistemas(state, datos) {
            state.sistemas = datos;
        },
        asignarProvincias(state, datos) {
            state.provincias = datos;
        },
        asignarCorreos(state, datos) {
            state.correos = datos;
        },
        asignarEmpresas(state, datos) {
            state.empresas = datos;
        },
        asignarSeries(state, datos) {
            state.series = datos;
        },
        asignarImpuestos(state, datos) {
            state.impuestos = datos;
        },
    },
    getters: {
        sistemas(state) {
            return state.sistemas;
        },
        provincias(state) {
            return state.provincias;
        },
        correos(state) {
            return state.correos;
        },
        empresas(state) {
            return state.empresas;
        },
        series(state) {
            return state.series;
        },
        impuestos(state) {
            return state.impuestos;
        },
    },
    actions: {
        async obtenerSistemas(context) {
            if (context.state.sistemas.length === 0) {
                const serv = new OtrosService();
                const datos = await serv.getSistemas();
                context.commit('asignarSistemas', datos);
            }
            return this.sistemas;
        },
        async obtenerProvincias(context) {
            if (context.state.provincias.length === 0) {
                const serv = new OtrosService();
                const datos = await serv.getProvincias();
                context.commit('asignarProvincias', datos);
            }
            return this.provincias;
        },
        async obtenerCorreos(context) {
            if (context.state.correos.length === 0) {
                const serv = new OtrosService();
                const datos = await serv.getCorreos();
                context.commit('asignarCorreos', datos);
            }
            return this.correos;
        },
        async obtenerEmpresas(context) {
            if (context.state.empresas.length === 0) {
                const serv = new OtrosService();
                const datos = await serv.getEmpresas();
                context.commit('asignarEmpresas', datos);
            }
            return this.empresas;
        },
        async obtenerSeries(context) {
            if (context.state.series.length === 0) {
                const serv = new SeriesService();
                const datos = await serv.getSeries();
                context.commit('asignarSeries', datos);
            }
        },
        async obtenerImpuestos(context) {
            if (context.state.impuestos.length === 0) {
                const serv = new OtrosService();
                const datos = await serv.getImpuestos();
                context.commit('asignarImpuestos', datos);
            }
        },
        async obtenerDatosGenerales(context) {
            try {
                context.dispatch('obtenerSistemas');
                context.dispatch('obtenerProvincias');
                context.dispatch('obtenerCorreos');
                context.dispatch('obtenerEmpresas');
                context.dispatch('obtenerSeries');
                context.dispatch('obtenerImpuestos');
            }
            catch (error) {
                console.log('ERROR: ' + error);
            }
        },
    }
}