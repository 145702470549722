<template>
    <div class="card" :class="'card-' + tipo">
        <div class="card-header">
            <slot name="header">
                <h3 class="card-title">{{ titulo }}</h3>
                <div class="card-tools">
                    <boton-guardar v-if="botonGuardar" @click="guardar" :guardando="guardando"></boton-guardar>
                </div>
            </slot> 
        </div>
        <div class="card-body">

            <slot/>
                            
        </div>
        <div class="card-footer" v-if="haySlotFooter">
            <slot name="footer" />
        </div>
        <div class="overlay" v-if="cargando">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        tipo: {
            type: String,
            default: 'primary'
        },
        titulo: {
            type: String,
            required: false
        },
        botonGuardar: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            guardando: false,
        }
    },
    emits: ['cambios'],
    computed: {
        /*guardando() {
            return this.$store.getters.guardando;
        },*/
        cargando() {
            return this.$store.getters.cargando;
        },
        haySlotFooter() {
            return !!this.$slots.footer;
        }
    },
    methods: {
        guardar() {
            this.$emit('cambios');
        }
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutacion, state) => {
            if (mutacion.type === 'cambiarGuardando') {
                this.guardando = state.guardando;
            }
        });
    },
    beforeUnmount() {
        this.unsubscribe();
    }
}
</script>