<template>
  <!-- Renderiza un item de menu del estilo AdminLTE -->
  <li class="nav-item" :class="clasesNavItem">
    <app-link :to="ruta" class="nav-link" :style="style" :class="{ active: activo }">
      <i style="float: inline-start; margin-top: 4px;" class="top left nav-icon" :class="clasesIcono"></i>
      <p><slot /></p>
    </app-link>
  </li>
</template>

<script>
export default {
  props: [
    "ruta",
    "clasesIcono",
    "nombre",
    "clasesNavItem",
    "activo",
    "style",
  ],
};
</script>
<style scoped>
</style>
