import { createStore } from "vuex";
import datosApp from './datosapp';
import especialidades from './especialidades';
import createPersistedState from "vuex-persistedstate";
import router from '../router.js';
import { PwgsApi } from "../services/PwgsApi.js";

const store = createStore({
    plugins: [createPersistedState()],
    modules: {
        datosApp,
        especialidades,
    },
    state() {
        return {
            guardando: false,
            cargando: false,
            rutas: [],
            menu: [
                {
                  nombre: 'Escritorio',
                  ruta: '/escritorio',
                  clasesIcono: 'fas fa-home'
                },
                {
                  nombre: 'Configuración',
                  ruta: '/configuracion',
                  clasesIcono: 'fas fa-cogs',

                },
                {
                    nombre: 'Planning',
                    ruta: '/planning',
                    clasesIcono: 'fas fa-calendar-alt'
                    
                },
                {
                  nombre: 'Servicios',
                  ruta: '/servicios',
                  clasesIcono: 'fas fa-clipboard-list',
                  clasesNavItem: 'menu-open',
                  submenu: [
                    {
                      nombre: 'Nuevo Servicio',
                      ruta: '/servicio/nuevo',
                      clasesIcono: 'fas fa-folder-plus'
                    },
                  ]
                },
                 {
                  nombre: 'Informes',
                  ruta: '/Informes',
                  clasesIcono: 'fas fa-desktop',
                  clasesNavItem: 'menu-open',
                },
                {
                    nombre: 'Chat',
                    ruta: '/chat',
                    clasesIcono: 'fab fa-weixin'                    
                },
            ]
        }
    },
    mutations: {
        agregarVentana(state, ruta) {
            const miRuta = {
              fullPath: ruta.fullPath,
              name: ruta.name,
              path: ruta.path,
            };
            let idruta='';
            if(ruta.name=='Servicio'){
                idruta = ruta.path.split('/', 3)[2];
                console.log("idruta",idruta);
                let nombreservicio = '';
                nombreservicio = localStorage.getItem("nombreservicio"+idruta);
                if(nombreservicio){
                    miRuta.name = 'Servicio: '+nombreservicio;
                }
           }
            if (ruta.name === 'Inicio' ||
                state.rutas.find(rutaBuscar => rutaBuscar.fullPath === ruta.fullPath)) {
                return;
            }
            state.rutas.push(miRuta);
        },
        /**
         * Cambia la ruta de una de las pestañas por otra, como cuando se crea un registro nuevo con ruta propia
         * y la sustituimos por la del elemento registro creado. Por ejemplo, al crear una nueva compañía
         * @param {*} state 
         * @param {*} rutas 
         */
        reemplazarVentana(state, rutas) {
            const indice = state.rutas.findIndex(ruta => ruta.fullPath === rutas.from.fullPath);
            const miRuta = {
            fullPath: rutas.to.fullPath,
            name: rutas.to.name,
            path: rutas.to.path,
            }
            state.rutas.splice(indice, 1, miRuta);
        },
        
        quitarRuta(state, ruta) {
            state.rutas = state.rutas.filter(rutaActual => rutaActual.fullPath !== ruta.fullPath);
        },
        async cerrarTodoDelTodo(state) { 
            state.rutas.splice(1);
            state.rutas[0].name = "Escritorio";
            state.rutas[0].fullPath = "/escritorio";
            state.rutas[0].path = "/escritorio";
            console.log("rutaaa", state.rutas);
        },
     async cerrarTodo(state) {
         let auxnuevorutas = [];
         let subidadatos = {
             name: "Escritorio",
             fullPath: "/escritorio",
             path: "/escritorio",
         }
         auxnuevorutas.push(subidadatos);
         for (let i = 0; i < state.rutas.length; i++){
             console.log("i", i);
             if (state.rutas[i].path.startsWith('/servicios/')) {
                        
                        let idruta = state.rutas[i].path.split('/', 3)[2];
                        const permitir = localStorage.getItem('permitir' + idruta);
                        const permitirtipodanyo = localStorage.getItem('permitirtipodanyo' + idruta);
                        const permitirfechaespera = localStorage.getItem('permitirfechaespera' + idruta);   
                        if (permitir == "true" && permitirtipodanyo == "true" && permitirfechaespera == "true") 
                            {
                        const api = new PwgsApi();
                        try {
                            await api.put('servicios/' + idruta + '/cerrar');
                            localStorage.removeItem("pestanyactivaservicio" + idruta);
                        }
                        catch (error) {
                            this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                            }
                        }
                        else {
                            auxnuevorutas.push(state.rutas[i]);
                        }
                    }
                    
                   
                }
         
                state.rutas = auxnuevorutas;
                router.push({ name: "Escritorio" });

        },
      cerrarResto(state, ruta) {
          state.rutas = state.rutas.filter(rutaActual => rutaActual.fullPath === ruta.fullPath);
         
        },
        cambiarCargando(state, estado) {
          state.cargando = estado;
        },
        cambiarGuardando(state, estado) {
          state.guardando = estado;
        },
        guardarVentanas(state, ventanas) {
            state.rutas = ventanas;
         }
    },
    getters: {
        ventanas(state) {
            return state.rutas;
        },
        numVentanas(state) {
            return state.rutas.length;
        },
        ultimaRuta(state) {
            return state.rutas.length > 0 ? state.rutas[state.rutas.length - 1] : false;
        },
        menu(state) {
            return state.menu;
        },
        cargando(state) {
            return state.cargando;
        },
        guardando(state) {
            return state.guardando;
        },
    },
    actions: {
        agregarVentana(context, ruta) {
            context.commit('agregarVentana', ruta);
        },
        reemplazarVentana({commit}, rutas) {
            commit('reemplazarVentana', rutas);
        },
        guardarVentanas({commit}, rutas) {
            commit('guardarVentanas', rutas);
        },
        quitarRuta(context, ruta) {
            context.commit('quitarRuta', ruta);
        },
        cerrarTodo({commit}) {
            commit('cerrarTodo');
        },
        cerrarTodoDelTodo({commit}) {
            commit('cerrarTodoDelTodo');
        },
        cerrarResto({commit}, ruta) {
            commit('cerrarResto', ruta);
        },
        cambiarCargando({commit}, estado) {
            commit('cambiarCargando', estado);
        },
        cambiarGuardando({commit}, estado) {
            commit('cambiarGuardando', estado);
        },
    }
});

export default store;